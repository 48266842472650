import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import {
  ImageBeforeCTAs,
  ImageBeforeHeader,
  ImageInMiddle,
  ImageOnLeft,
  ImageOnRight,
  ImageOnTop,
} from './hero-card-types';
import {
  colorBlack,
  desktopMin,
  firstLetterToUpper,
  hexToRgbA,
  invertHex,
  tabletMax,
} from '~/common/utils';
import { Waypoint } from 'react-waypoint';
import { scroller } from 'react-scroll';
import _ from 'lodash';
import {
  IHeroCardBase,
  HeroCardTypesEnum,
  IHeroCardProps,
  HeroCardMobileTypesEnum,
} from './store';
import uuid from 'react-uuid';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useRTETooltipPositionCorrector } from '~/common/hooks/use-rteTooltipPositionCorrector';
import { useNavOffsetHeight } from '~/common/hooks/use-navOffsetHeight';
import { PortalProvider } from 'react-portal-hook';

const getHeroCardComponent = (
  type?: keyof typeof HeroCardTypesEnum | keyof typeof HeroCardMobileTypesEnum,
  isMobile?: boolean
) => {
  const errorMessage = `Unknown hero card ${!!!isMobile ? '' : 'mobile'}type.`;
  if (!!!type) throw new Error(errorMessage);

  if (!!!isMobile) {
    if (type === HeroCardTypesEnum.imageOnRight) return ImageOnRight;
    else if (type === HeroCardTypesEnum.imageOnLeft) return ImageOnLeft;
    else if (type === HeroCardTypesEnum.imageOnTop) return ImageOnTop;
    else if (type === HeroCardTypesEnum.imageInMiddle) return ImageInMiddle;
  } else {
    if (type === HeroCardMobileTypesEnum.imageBeforeCTAs)
      return ImageBeforeCTAs;
    else if (type === HeroCardMobileTypesEnum.imageBeforeHeader)
      return ImageBeforeHeader;
  }
  throw new Error(errorMessage);
};

export const hasNonMediaContents = ({
  contentHeader,
  contentBody,
  contentCTAs,
  keyFeatures,
  articles,
  isMobile,
}: IHeroCardProps) => {
  const result =
    (contentHeader && contentHeader.length > 0) ||
    (contentBody && contentBody.length > 0) ||
    (keyFeatures && keyFeatures.length > 0) ||
    (contentCTAs &&
      contentCTAs?.filter(
        (x) => !(x.ctaHidden || (isMobile && x.ctaHideInMobile))
      ).length > 0) ||
    (articles && articles.filter((x) => !x.hidden).length > 0);
  return result;
};

export const HeroCard = React.memo((props: IHeroCardBase) => {
  const [index, setIndex] = useState<number>(-1);
  const [heightOffset, setHeightOffset] = useState<number>(0);

  const ref = React.useRef<HTMLDivElement>(null);

  const [isMobile, setIsMobile] = useState(
    useMediaQuery(
      { query: `(max-width: ${tabletMax}px)` },
      undefined,
      (matches) => {
        setIsMobile(matches);
      }
    )
  );

  const defaultHeightOffset = () => (isMobile ? 55 : 85);
  const offsetHeight = useNavOffsetHeight(isMobile, defaultHeightOffset());

  const onLoad = useCallback(() => {
    const siblings = ref.current?.parentNode?.parentNode?.children;
    const idx = _.findIndex(siblings, (x) => x === ref.current?.parentNode);
    setIndex(idx);
    setHeightOffset(offsetHeight);
  }, []);
  useEffect(() => onLoad(), [onLoad, isMobile]);

  return (
    <div
      className={cn('full-device-width', styles.HeroCardWrapper)}
      style={
        props.heroImageFullCover && props.cardFullscreen && !isMobile
          ? {
              minHeight: `calc(100vh - ${heightOffset}px)`,
            }
          : props.heroImageFullCover && !props.cardFullscreen && !isMobile
          ? {
              minHeight: `calc(100vw * 9 / 16)`,
            }
          : {}
      }
      key={uuid()}
      ref={ref}
    >
      {index >= 0 && (
        <HeroCardComponent {...{ ...props, index, heightOffset }} />
      )}
    </div>
  );
});

const HeroCardComponent = React.memo((props: IHeroCardProps) => {
  const {
    id,
    index,
    cardType,
    mobileCardType,
    heightOffset,
    cardFullscreen,
    contentBackgroundColor,
    heroImageFullCover,
    cardIndent,
    cardPadding,
    cardShadow,
    cardBackgroundColor,
    heroImageMobileFullCover,
    heroImageMobileFitContentOnCover,
  } = props;
  const heroCardId = `${id}`;

  const [isMobile, setIsMobile] = useState(
    useMediaQuery(
      { query: `(max-width: ${tabletMax}px)` },
      undefined,

      (matches) => {
        setIsMobile(matches);
      }
    )
  );
  useRTETooltipPositionCorrector(isMobile);

  const [visible, setVisible] = useState(isMobile || false);

  const Component = getHeroCardComponent(cardType);
  const MobileComponent = getHeroCardComponent(mobileCardType, true);

  // callback function to call when event triggers
  const onPageLoad = () => scrollIntoSection();

  // This will run one time after the component mounts
  useEffect(() => {
    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  const scrollIntoSection = () => {
    // url excluding query string
    const url = new URL(window.location.pathname, window.location.origin);
    const prevSectionId = window.localStorage.getItem('prevSectionId');
    if (
      prevSectionId &&
      heroCardId === prevSectionId &&
      document.referrer.includes(url.href)
    ) {
      window.localStorage.removeItem('prevSectionId');
      scroller.scrollTo(prevSectionId, {
        smooth: true,
        offset: -heightOffset,
        duration: 0,
        ignoreCancelEvents: true,
        isDynamic: true,
      });
    }
  };

  return (
    <div
      id={`cid-${heroCardId}`}
      key={index}
      style={{
        ...(cardFullscreen && heroImageFullCover
          ? { maxHeight: `calc(100vh - ${heightOffset}px)` }
          : {}),
        ...(cardBackgroundColor
          ? {
              backgroundColor: `#${cardBackgroundColor}`,
            }
          : {}),
      }}
    >
      <PortalProvider>
        <Waypoint
          topOffset={isMobile ? `${heightOffset}px` : '70%'}
          bottomOffset={isMobile ? `${heightOffset}px` : '70%'}
          scrollableAncestor={'window'}
          onEnter={() => {
            setVisible(true);
          }}
        >
          <div
            className={cn(
              styles.HeroCard,
              styles[`HeroCard${isMobile ? 'Mobile' : ''}`],
              styles[
                `HeroCard${isMobile ? 'Mobile' : ''}${firstLetterToUpper(
                  isMobile ? mobileCardType : cardType
                )}`
              ],
              {
                [styles.HeroCardFullCover]:
                  (!isMobile && heroImageFullCover) ||
                  (isMobile &&
                    (heroImageMobileFullCover ||
                      heroImageMobileFitContentOnCover)),
                [styles.HeroCardIndent]: cardIndent,
                [styles.HeroCardShadow]: cardShadow,
              }
            )}
            style={{
              ...(contentBackgroundColor
                ? { backgroundColor: `#${contentBackgroundColor}` }
                : {}),
              ...(cardPadding && !isMobile ? { margin: cardPadding } : {}),
              ...(cardShadow
                ? {
                    boxShadow: `0px 0px ${isMobile ? '5' : '20'}px ${
                      isMobile ? '5' : '10'
                    }px ${hexToRgbA(
                      invertHex(cardBackgroundColor || colorBlack),
                      '0.33'
                    )}`,
                  }
                : {}),
            }}
          >
            <MediaQuery maxWidth={tabletMax}>
              <MobileComponent
                {...{
                  ...props,
                  visible,
                  index,
                  isMobile,
                }}
              />
            </MediaQuery>
            <MediaQuery minWidth={desktopMin}>
              <Component
                {...{
                  ...props,
                  visible,
                  index,
                }}
              />
            </MediaQuery>
          </div>
        </Waypoint>
      </PortalProvider>
    </div>
  );
});
