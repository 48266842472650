import React from 'react';
import styles from '../index.module.scss';
import { IHeroCardProps } from '../store';
import { hexToRgbA } from '~/common/utils';
import {
  HeroCardContent,
  HeroCardContentBody,
  HeroCardContentHeader,
  HeroCardCTAs,
  HeroCardMedia,
  HeroCardArticles,
} from '../components';
import HeroCardKeyFeatures from '../components/HeroCardKeyFeatures';
const ImageBeforeHeader = React.memo((props: IHeroCardProps) => {
  const {
    heroImageMobileFullCover,
    heroImageMobileFitContentOnCover,
    contentBackgroundColor,
    heroMediaSize,
    contentVerticalAlignment,
  } = props;

  const mapContentAlignment = () => {
    switch (contentVerticalAlignment?.toLowerCase()) {
      case 'space between':
        return 'space-between';
      case 'center':
        return 'center';
      case 'bottom':
        return 'end';
      case 'top':
      default:
        return 'start';
    }
  };

  return (
    <>
      <HeroCardMedia
        {...props}
        style={
          heroImageMobileFitContentOnCover
            ? {
                position: 'relative',
              }
            : {}
        }
      />
      <HeroCardContent
        {...props}
        className={[
          styles.HeroCardContentMobile,
          {
            [styles.HeroCardContentFullCover]:
              heroImageMobileFullCover || heroImageMobileFitContentOnCover,
          },
        ]}
        style={{
          ...((heroImageMobileFullCover || heroImageMobileFitContentOnCover) &&
          contentBackgroundColor
            ? {
                backgroundColor: `${hexToRgbA(
                  `#${props.contentBackgroundColor}`,
                  '.25'
                )}`,
              }
            : {}),
          ...(heroImageMobileFitContentOnCover
            ? {
                position: 'absolute',
              }
            : {}),
          ...(heroMediaSize ? { flex: `0 1 ${100 - heroMediaSize}%` } : {}),
          justifyContent: mapContentAlignment(),
        }}
        contentStyle={{
          ...(heroImageMobileFitContentOnCover
            ? {
                display: 'flex',
                flexDirection: 'column',
              }
            : {}),
        }}
      >
        <HeroCardContentHeader {...props} />
        <HeroCardContentBody {...props} />
        <HeroCardKeyFeatures {...props} />
        <HeroCardCTAs {...props} />
        <HeroCardArticles {...props} />
      </HeroCardContent>
    </>
  );
});
export default ImageBeforeHeader;
