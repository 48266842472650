import React from 'react';
import styles from '../index.module.scss';
import cn, { Argument } from 'classnames';
import { firstLetterToUpper } from '~/common/utils';
import { IHeroCardProps } from '../store';

const HeroCardContent = React.memo(
  (
    props: IHeroCardProps & {
      style?: React.CSSProperties;
      contentStyle?: React.CSSProperties;
      className?: Argument | Argument[];
      children?: JSX.Element | JSX.Element[];
    }
  ) => {
    const {
      cardType,
      contentPadding,
      contentVerticalAlignment,
      contentBody,
      contentHeader,
      children,
      style,
      contentStyle,
      className,
      isMobile,
    } = props;

    const mapContentAlignment = () => {
      switch (contentVerticalAlignment?.toLowerCase()) {
        case 'top':
          return 'start';
        case 'bottom':
          return 'end';
        case 'space between':
          return 'space-between';
        case 'center':
        default:
          return 'center';
      }
    };

    return (
      <>
        {(contentBody || contentHeader) && (
          <div
            className={cn(
              styles.HeroCardContentContainer,
              styles[`HeroCardContentContainer${firstLetterToUpper(cardType)}`],
              className
            )}
            style={{ ...style, justifyContent: mapContentAlignment() }}
          >
            <div
              className={cn(
                styles.HeroCardContent,
                styles[`HeroCardContent${firstLetterToUpper(cardType)}`]
              )}
              style={{
                ...contentStyle,
                ...(contentPadding && !isMobile
                  ? { padding: contentPadding }
                  : {}),
                justifyContent: mapContentAlignment(),
              }}
            >
              {children}
            </div>
          </div>
        )}
      </>
    );
  }
);
export default HeroCardContent;
