import React from 'react';
import { IHeroCardProps } from '../store';
import {
  HeroCardContent,
  HeroCardContentBody,
  HeroCardContentHeader,
  HeroCardCTAs,
  HeroCardMedia,
} from '../components';
import { hexToRgbA } from '~/common/utils';
import styles from '../index.module.scss';

const ImageInMiddle = React.memo((props: IHeroCardProps) => {
  const normalView = () => (
    <HeroCardContent {...props}>
      <HeroCardContentHeader {...props} />
      <HeroCardContentBody {...props} />
      <HeroCardMedia
        {...{
          ...props,
          style: {
            padding: `0px ${(100 - (props.heroMediaSize || 100)) / 2}%`,
          },
        }}
      />
      <HeroCardCTAs {...props} />
    </HeroCardContent>
  );

  const fullMediaBackgroundView = () => (
    <>
      <HeroCardMedia
        {...{
          ...props,
        }}
      />
      <HeroCardContent
        {...props}
        className={{
          [styles.HeroCardContentFullCover]: props.heroImageFullCover,
        }}
        style={{
          ...(props.heroImageFullCover && props.contentBackgroundColor
            ? {
                backgroundColor: `${hexToRgbA(
                  `#${props.contentBackgroundColor}`,
                  '.25'
                )}`,
              }
            : {}),
          ...{
            paddingTop: '40px',
            paddingBottom: '40px',
          },
        }}
      >
        <div>
          <HeroCardContentHeader {...props} />
          <HeroCardContentBody {...props} />
        </div>
        <HeroCardCTAs {...props} />
      </HeroCardContent>
    </>
  );

  return (
    <>{!props.heroImageFullCover ? normalView() : fullMediaBackgroundView()}</>
  );
});
export default ImageInMiddle;
