import React from 'react';
import styles from '../index.module.scss';
import { hexToRgbA } from '~/common/utils';
import { IHeroCardProps } from '../store';
import {
  HeroCardContent,
  HeroCardContentBody,
  HeroCardContentHeader,
  HeroCardCTAs,
  HeroCardMedia,
  HeroCardArticles,
} from '../components';
import HeroCardKeyFeatures from '../components/HeroCardKeyFeatures';

const ImageOnRight = React.memo((props: IHeroCardProps) => {
  const { heroImageFullCover, contentBackgroundColor, heroMediaSize } = props;
  return (
    <>
      <HeroCardMedia
        {...props}
        style={{
          ...(!heroImageFullCover
            ? {
                flex: `1 0 ${heroMediaSize}%`,
              }
            : {}),
        }}
      />
      <HeroCardContent
        {...props}
        className={{
          [styles.HeroCardContentFullCover]: heroImageFullCover,
        }}
        style={{
          ...(heroImageFullCover && contentBackgroundColor
            ? {
                background: `linear-gradient(
                    to left,
                    transparent 0%,
                    ${hexToRgbA(`#${contentBackgroundColor}`, '1')} 100%
                  )`,
              }
            : {}),
          ...(heroMediaSize ? { flex: `0 1 ${100 - heroMediaSize}%` } : {}),
        }}
      >
        <HeroCardContentHeader {...props} />
        <HeroCardContentBody {...props} />
        <HeroCardKeyFeatures {...props} />
        <HeroCardCTAs {...props} />
        <HeroCardArticles {...props} />
      </HeroCardContent>
    </>
  );
});
export default ImageOnRight;
