import React from 'react';
import styles from '../index.module.scss';
import { IHeroCardProps } from '../store';
import { hexToRgbA } from '~/common/utils';
import {
  HeroCardContent,
  HeroCardContentBody,
  HeroCardContentHeader,
  HeroCardCTAs,
  HeroCardMedia,
  HeroCardArticles,
} from '../components';
const ImageBeforeCTAs = React.memo((props: IHeroCardProps) => {
  const {
    heroImageMobileFullCover,
    heroImageMobileFitContentOnCover,
    contentBackgroundColor,
    heroMediaSize,
  } = props;
  return (
    <HeroCardContent
      {...props}
      className={[
        styles.HeroCardContentMobile,
        {
          [styles.HeroCardContentFullCover]:
            heroImageMobileFullCover || heroImageMobileFitContentOnCover,
        },
      ]}
      style={{
        ...((heroImageMobileFullCover || heroImageMobileFitContentOnCover) &&
        contentBackgroundColor
          ? {
              backgroundColor: `${hexToRgbA(
                `#${props.contentBackgroundColor}`,
                '.25'
              )}`,
            }
          : {}),
        ...(heroMediaSize ? { flex: `0 1 ${100 - heroMediaSize}%` } : {}),
      }}
    >
      <HeroCardContentHeader {...props} />
      <HeroCardContentBody {...props} />
      <HeroCardMedia {...props} />
      <HeroCardCTAs {...props} />
      <HeroCardArticles {...props} />
    </HeroCardContent>
  );
});
export default ImageBeforeCTAs;
