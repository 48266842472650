import { CallToAction, FeatureIcon } from '~/common/models';
import { Property } from 'csstype';

export enum HeroCardTypesEnum {
  imageOnRight = 'imageOnRight',
  imageOnLeft = 'imageOnLeft',
  imageOnTop = 'imageOnTop',
  imageInMiddle = 'imageInMiddle',
}

export enum HeroCardMediaPinDirectionEnum {
  up = 'Up',
  down = 'Down',
}

export enum HeroCardMediaPinTextPositionEnum {
  left = 'Left',
  right = 'Right',
  center = 'Center',
}

export enum HeroCardMobileTypesEnum {
  imageBeforeCTAs = 'imageBeforeCTAs',
  imageBeforeHeader = 'imageBeforeHeader',
}

export interface IHeroCardBase {
  id: number;
  cardType?: keyof typeof HeroCardTypesEnum;
  mobileCardType?: keyof typeof HeroCardMobileTypesEnum;
  cardFullscreen?: boolean;
  cardShadow?: boolean;
  cardIndent?: boolean;
  cardPadding?: string;
  cardBackgroundColor?: string;

  heroMediaSize?: number;
  heroMediaPadding?: string;

  heroImage?: string;
  heroImageFullCover?: boolean;
  heroMobileImage?: string;
  heroImageMobileFullCover?: boolean;
  heroImageMobileFitContentOnCover?: boolean;

  disclaimerOnImage?: string;
  disclaimerFontColor?: string;
  featureIcons?: FeatureIcon[];
  featureInfo?: IHeroCardMediaInfo[];
  featureInfoPosition?: 'Left' | 'Bottom';
  featurePins?: IHeroCardMediaPin[];
  featureToggles?: IHeroCardMediaToggle[];
  attachTogglesOnPins?: boolean;

  hero360ViewUrl?: string;
  hero360ViewThumbnail?: string;

  heroVideo?: string;
  heroVideoAutoplay?: boolean;
  heroVideoLoop?: boolean;
  heroVideoMuted?: boolean;
  heroVideoThumbnail?: string;
  heroVideoEmbed?: string;

  contentHeader?: string;
  headerDisclaimer?: string;
  contentBody?: string;
  contentVerticalAlignment?: string;
  contentPadding?: string;
  contentBackgroundColor?: string;
  contentFontColor?: string;
  contentCTAs?: CallToAction[];
  contentCTAsAlignment?: Property.TextAlign;
  contentCTAsStackable?: boolean;
  keyFeatures?: string[];
  articles?: IHeroCardArticle[];
  articleBackgroundColor?: string;
  articleFontColor?: string;
  swapArticleThumbnailPosition?: boolean;
}

export interface IHeroCardMediaToggle {
  toggleXPosition?: number;
  toggleYPosition?: number;
  toggleHeader?: string;
  toggleContent?: string;
  toggleContentFontColor?: string;
  toggleContentBackgroundColor?: string;
  toggleIconColor?: string;
  toggleIconFillColor?: string;
}

export interface IHeroCardMediaInfo {
  header?: string;
  content?: string;
}

export interface IHeroCardMediaPin {
  pinDirection?: keyof typeof HeroCardMediaPinDirectionEnum;
  pinXPosition?: number;
  pinYPosition?: number;
  pinLength?: number;
  pinColor?: string;
  pinFeatureText?: string;
  pinTextPosition?: keyof typeof HeroCardMediaPinTextPositionEnum;
  pinTextBackgroundColor?: string;
  hideInMobile?: boolean;
}

export interface IHeroCardMediaPinKeyframe {
  name: string;
  percent?: string;
  backgroundColor?: string;
  animationProps: Record<string, React.CSSProperties | string>;
}

interface IHeroCardArticle {
  title?: string;
  author?: string;
  readingTime?: string;
  thumbnailImageUrl?: string;
  url?: string;
  linkTarget?: '_self' | '_blank';
  hidden?: boolean;
}

interface IHeroCardMisc {
  index?: number;
  visible?: boolean;
  heightOffset: number;
  isMobile?: boolean;
  style?: React.CSSProperties;
}

export interface IHeroCardProps extends IHeroCardBase, IHeroCardMisc {}
