import React from 'react';
import styles from '../index.module.scss';
import { IHeroCardProps } from '../store';
import { animated, useSpring } from '@react-spring/web';
import { mediaAnimationProps } from '../common';
import { colorWhite, hexToRgbA } from '~/common/utils';
import { SvgIcon } from '~/common/components/ui-elements';

const HeroCardKeyFeatures = React.memo(
  ({ keyFeatures, visible, contentFontColor }: IHeroCardProps) => {
    const animateStyle = useSpring(mediaAnimationProps(visible));
    return (
      <>
        {keyFeatures && keyFeatures.length > 0 && (
          <animated.div
            className={styles.HeroCardKeyFeatures}
            style={animateStyle}
          >
            {keyFeatures.map((kf, idx) => (
              <div key={idx} className={styles.HeroCardKeyFeature}>
                <div
                  style={{
                    boxShadow: `0px 0px 1px 1px ${hexToRgbA(colorWhite)}`,
                  }}
                >
                  <SvgIcon type="check" size={0.8} color={`#${colorWhite}`} />
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: kf as string }}
                  ref={(element) => {
                    if (element && contentFontColor) {
                      element.style.setProperty(
                        'color',
                        `#${contentFontColor}`,
                        'important'
                      );
                    }
                  }}
                />
              </div>
            ))}
          </animated.div>
        )}
      </>
    );
  }
);
export default HeroCardKeyFeatures;
