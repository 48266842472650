import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from '../index.module.scss';
import {
  IconEnum,
  TooltipDisclaimer,
} from '../../../common/components/ui-elements';
import {
  colorBlack,
  colorPitchBlack,
  colorWhite,
  delay,
  firstLetterToUpper,
  hexToRgbA,
} from '~/common/utils';
import { useSpring, animated } from 'react-spring';
import {
  IHeroCardMediaPin,
  IHeroCardMediaPinKeyframe,
  IHeroCardProps,
  IHeroCardMediaInfo,
  HeroCardMediaPinDirectionEnum,
  IHeroCardMediaToggle,
} from '../store';
import { SvgIcon } from '~/common/components/ui-elements/svg-icon';
import { mediaAnimationProps } from '../common';
import {
  AnimatedNumberIcon,
  CallToActionIcon,
  ImageIcon,
} from '~/common/components';
import { useRenderSize } from '~/common/hooks/use-windowsize';
import { hasNonMediaContents } from '..';

const HeroCardMediaPinKeyframe = React.memo(
  ({
    name,
    percent,
    backgroundColor,
    animationProps,
  }: IHeroCardMediaPinKeyframe) => {
    const toCss = (cssObject: React.CSSProperties | string) =>
      typeof cssObject === 'string'
        ? cssObject
        : Object.keys(cssObject).reduce((accumulator, key) => {
            const cssKey = key.replace(/[A-Z]/g, (v) => `-${v.toLowerCase()}`);
            const cssValue = (cssObject as any)[key]
              .toString()
              .replace("'", '');
            return `${accumulator}${cssKey}:${cssValue};`;
          }, '');
    const cssRules = Object.entries(animationProps).map(
      ([key, value]) => `${key}: ${toCss(value)}`
    );
    return (
      <style>
        {`.${name}::before { animation: ${name} 1.5s forwards;
        background-color: ${backgroundColor}; }`}
        {`.${name}::after { border-color: ${backgroundColor}; }`}
        {`@keyframes ${name} {${percent || ''} {${cssRules.join(';\n')}}}`}
      </style>
    );
  }
);

const HeroCardMediaPinToggle = React.memo(
  ({
    idx,
    visible,
    active,
    setActive,
    pin,
    ...toggle
  }: IHeroCardMediaToggle & {
    idx: number;
    visible?: boolean;
    active: number;
    setActive: React.Dispatch<React.SetStateAction<number>>;
    pin: IHeroCardMediaPin;
  }) => {
    const animateStyle = useSpring(mediaAnimationProps(visible));

    const handleClick = () => {
      setActive(active === idx ? -1 : idx);
    };

    return (
      <>
        <animated.div
          style={{
            ...animateStyle,
            ...(toggle.toggleContentBackgroundColor
              ? {
                  backgroundColor: hexToRgbA(
                    `#${toggle.toggleContentBackgroundColor}`,
                    '0.75'
                  ),
                }
              : {}),
            ...(toggle.toggleContentFontColor
              ? {
                  color: `#${toggle.toggleContentFontColor}`,
                }
              : {}),
          }}
          className={cn(styles.HeroCardMediaPinToggleContent, {
            [styles.HeroCardMediaPinToggleContentDown]:
              pin.pinDirection?.toLowerCase() ===
              HeroCardMediaPinDirectionEnum.down.toLowerCase(),
            [styles.HeroCardMediaPinToggleContentActive]: idx === active,
          })}
          dangerouslySetInnerHTML={{
            __html: toggle.toggleContent as string,
          }}
        />
        <div key={idx} className={styles.HeroCardMediaPinToggle}>
          <SvgIcon
            className={styles.HeroCardMediaPinToggleIcon}
            type={idx === active ? IconEnum.minus : IconEnum.plus}
            color={`#${toggle.toggleIconColor || pin.pinColor || colorBlack}`}
            size={0.67}
            strokeWidth={1.5}
            onClick={handleClick}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent:
                pin.pinDirection?.toLowerCase() ===
                HeroCardMediaPinDirectionEnum.down.toLowerCase()
                  ? 'start'
                  : 'end',
            }}
          />
        </div>
      </>
    );
  }
);

const HeroCardMediaPin = React.memo(
  (props: {
    pin: IHeroCardMediaPin;
    idx: number;
    id: string;
    visible?: boolean;
    toggle?: IHeroCardMediaToggle;
    active: number;
    setActive: React.Dispatch<React.SetStateAction<number>>;
  }) => {
    const { pin, idx, id, visible, toggle, active, setActive } = props;
    const animateStyle = useSpring(mediaAnimationProps(visible));
    const txtStyle = {
      ...{
        color: `#${pin.pinColor || colorBlack}`,
      },
      ...(pin.pinTextBackgroundColor
        ? {
            backgroundColor: hexToRgbA(
              `#${pin.pinTextBackgroundColor}`,
              '0.75'
            ),
            borderRadius: '7px',
          }
        : {
            padding: '0px',
          }),
    };

    return (
      <div>
        <HeroCardMediaPinKeyframe
          name={`HeroCardMediaPin${id}`}
          percent={'100%'}
          backgroundColor={`#${pin.pinColor || colorBlack}`}
          animationProps={
            pin.pinDirection?.toLowerCase() ===
            HeroCardMediaPinDirectionEnum.up.toLowerCase()
              ? { top: `-100%`, height: `100%` }
              : { height: `100%` }
          }
        />
        <div
          className={`${`HeroCardMediaPin${id}`} ${cn(styles.HeroCardMediaPin, {
            [styles.HeroCardMediaPinUp]:
              pin.pinDirection?.toLowerCase() ===
              HeroCardMediaPinDirectionEnum.up.toLowerCase(),
          })}`}
          style={{
            height: `${pin.pinLength || 0}%`,
            top: `${pin.pinYPosition || 0}%`,
            left: `${pin.pinXPosition || 0}%`,
          }}
        >
          <animated.div
            className={cn(
              styles.HeroCardMediaPinText,
              styles[
                `HeroCardMediaPinText${firstLetterToUpper(pin.pinTextPosition)}`
              ],
              {
                [styles.DefaultDisclaimerColor]: [
                  colorBlack,
                  colorPitchBlack,
                ].includes(pin.pinColor || ''),
                [styles.HeroCardMediaPinTextToggle]: toggle,
              }
            )}
            style={{
              ...txtStyle,
              ...animateStyle,
              ...{
                direction: pin.pinTextPosition === 'left' ? 'rtl' : 'unset',
              },
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: (toggle
                  ? `<p>${toggle.toggleHeader}</p>`
                  : pin.pinFeatureText) as string,
              }}
            />
            {toggle && (
              <HeroCardMediaPinToggle
                key={idx}
                {...{
                  ...toggle,
                  idx,
                  visible,
                  active,
                  setActive,
                  pin,
                }}
              />
            )}
          </animated.div>
        </div>
      </div>
    );
  }
);

const HeroCardMediaPins = React.memo(
  ({
    pins,
    visible,
    id,
    featureToggles,
    attachTogglesOnPins,
  }: {
    pins?: IHeroCardMediaPin[];
    featureToggles?: IHeroCardMediaToggle[];
    visible?: boolean;
    id: number;
    attachTogglesOnPins?: boolean;
  }) => {
    const [active, setActive] = useState(0);
    return (
      <>
        {pins && pins.length > 0 && (
          <div className={styles.HeroCardMediaPins}>
            {pins.map((pin, idx) => {
              const toggle =
                attachTogglesOnPins &&
                featureToggles &&
                featureToggles.length > 0 &&
                idx <= featureToggles.length
                  ? featureToggles[idx]
                  : undefined;
              return (
                <HeroCardMediaPin
                  key={idx}
                  {...{
                    pin,
                    id: `${id}_${idx}`,
                    idx,
                    visible,
                    toggle,
                    active,
                    setActive,
                  }}
                />
              );
            })}
          </div>
        )}
      </>
    );
  }
);

const HeroCardMediaToggle = React.memo(
  ({
    idx,
    visible,
    active,
    setActive,
    ...toggleProps
  }: IHeroCardMediaToggle & {
    idx: number;
    visible?: boolean;
    active: number;
    setActive: React.Dispatch<React.SetStateAction<number>>;
  }) => {
    const animateStyle = useSpring(mediaAnimationProps(visible));

    const handleClick = () => {
      setActive(active === idx ? -1 : idx);
    };

    return (
      <>
        <div key={idx} className={styles.HeroCardMediaToggle}>
          <animated.div
            style={animateStyle}
            className={cn(styles.HeroCardMediaToggleContent, {
              [styles.HeroCardMediaToggleContentActive]: idx === active,
            })}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: toggleProps.toggleHeader as string,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: toggleProps.toggleContent as string,
              }}
            />
          </animated.div>
          <SvgIcon
            className={styles.HeroCardMediaToggleIcon}
            type={idx === active ? IconEnum.minusCircle : IconEnum.plusCircle}
            color={`#${colorPitchBlack}`}
            fill={`#${colorWhite}`}
            size={1.5}
            strokeWidth={1.5}
            style={{
              top: `${toggleProps.toggleYPosition || 0}%`,
              left: `${toggleProps.toggleXPosition || 0}%`,
            }}
            onClick={handleClick}
          />
        </div>
      </>
    );
  }
);

const HeroCardMediaToggles = React.memo(
  ({
    featureToggles,
    visible,
  }: {
    featureToggles?: IHeroCardMediaToggle[];
    visible?: boolean;
  }) => {
    const [active, setActive] = useState(0);
    return (
      <>
        {featureToggles && featureToggles.length > 0 && (
          <div className={styles.HeroCardMediaToggles}>
            {featureToggles.map((toggle, idx) => (
              <HeroCardMediaToggle
                key={idx}
                {...{ ...toggle, idx, visible, active, setActive }}
              />
            ))}
          </div>
        )}
      </>
    );
  }
);

const HeroCardMediaAccordion = React.memo(
  ({
    idx,
    visible,
    isExpand,
    ...toggleProps
  }: IHeroCardMediaToggle & {
    idx: number;
    visible?: boolean;
    isExpand: boolean;
  }) => {
    const animateStyle = useSpring(mediaAnimationProps(visible));
    const [expand, setExpand] = useState(isExpand);
    return (
      <div key={idx} className={styles.HeroCardMediaAccordion}>
        <animated.div
          style={animateStyle}
          className={styles.HeroCardMediaAccordionHeader}
          onClick={() => setExpand(!expand)}
        >
          <span>{toggleProps.toggleHeader}</span>
          <SvgIcon
            className={styles.HeroCardMediaToggleIcon}
            type={expand ? IconEnum.chevronUp : IconEnum.chevronDown}
            color={`#${colorWhite}`}
            size={1.2}
            strokeWidth={1}
            onClick={() => setExpand(!expand)}
          />
        </animated.div>
        {expand && (
          <animated.div
            style={{ ...animateStyle }}
            className={styles.HeroCardMediaAccordionContent}
            dangerouslySetInnerHTML={{
              __html: toggleProps.toggleContent as string,
            }}
          />
        )}
      </div>
    );
  }
);

const HeroCardFeatureInfo = React.memo(
  ({
    header,
    content,
    idx,
    featureInfoPosition,
  }: IHeroCardMediaInfo & { idx: number; featureInfoPosition?: string }) => {
    return (
      <div
        key={idx}
        className={cn(
          styles.feature,
          styles.HeroCardFeatureInfo,
          styles[`HeroCardFeatureInfo${featureInfoPosition}`]
        )}
      >
        <div dangerouslySetInnerHTML={{ __html: header as string }} />
        <div dangerouslySetInnerHTML={{ __html: content as string }} />
      </div>
    );
  }
);

const HeroCardMedia: React.FC<IHeroCardProps> = (props) => {
  const {
    id,
    cardType,
    heroImage,
    heroImageFullCover,
    heroMobileImage,
    heroImageMobileFullCover,
    heroImageMobileFitContentOnCover,
    disclaimerOnImage,
    disclaimerFontColor,
    featureIcons,
    featureInfo,
    featureInfoPosition,
    visible,
    featurePins,
    featureToggles,
    heroVideo,
    heroVideoAutoplay,
    heroVideoLoop,
    heroVideoMuted,
    heroVideoThumbnail,
    heroVideoEmbed,
    hero360ViewUrl,
    hero360ViewThumbnail,
    heroMediaSize,
    heroMediaPadding,
    isMobile,
    style,
    cardFullscreen,
    attachTogglesOnPins,
  } = props;
  const animateStyle = useSpring(mediaAnimationProps(visible));

  const ref = useRef<HTMLVideoElement>(null);
  const [playing, setPlaying] = useState(heroVideoAutoplay || false);
  const [viewing, setViewing] = useState(false);
  const [videoTime, setVideoTime] = useState(0);
  const { width } = useRenderSize();
  const maxWidth = width && heroMediaSize ? (width * heroMediaSize) / 100 : 0;
  const minHeight = (maxWidth * 9) / 16;
  const _featureIcons = isMobile
    ? featureIcons?.filter((x) => !x.hideOnMobile)
    : featureIcons;
  const _featurePins = isMobile
    ? featurePins?.filter((x) => !x.hideInMobile)
    : featurePins;

  const mediaRef = useRef<HTMLDivElement>(null);
  const featureInfoRef = useRef<HTMLDivElement>(null);
  const [mediaRefHeight, setMediaRefHeight] = useState(0);

  useEffect(() => {
    const adjustContainerHeight = () => {
      if (
        featureInfoRef.current &&
        mediaRef.current &&
        featureInfoRef.current.scrollHeight > mediaRef.current.scrollHeight
      ) {
        setMediaRefHeight(featureInfoRef.current.scrollHeight + 20);
      }
    };

    adjustContainerHeight();
    window.addEventListener('resize', adjustContainerHeight);

    return () => {
      window.removeEventListener('resize', adjustContainerHeight);
    };
  }, [mediaRef.current, featureInfoRef.current]);

  useEffect(() => {
    if (!visible) setViewing(false);
  }, [visible]);

  const handleClick = async (e) => {
    const _this = e.target.tagName === 'VIDEO' ? e.target : ref.current;
    if (_this) {
      if (_this.paused || (!heroVideoAutoplay && _this.ended)) {
        const _delay = 0.3;
        _this.currentTime = videoTime - _delay > 0 ? videoTime - _delay : 0;
        _this.play();
        await delay(_delay * 1000);
      } else {
        setVideoTime(_this.currentTime || 0);
        _this.load();
        _this.pause();
      }
      setPlaying(!_this.paused);
    }
    e.preventDefault();
  };

  const renderDisclaimer = () => {
    if (!disclaimerOnImage) return <></>;
    return (
      <TooltipDisclaimer
        className={cn(
          styles.DisclaimerOnImage,
          styles[`DisclaimerOnImage${firstLetterToUpper(cardType)}`]
        )}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: disclaimerOnImage as string,
          }}
          style={{ color: `#${disclaimerFontColor || colorWhite}` }}
        />
      </TooltipDisclaimer>
    );
  };

  return (
    <>
      {(heroVideo || heroVideoEmbed || hero360ViewUrl || heroImage) && (
        <div
          className={cn(styles.HeroCardMedia, {
            [styles.HeroCardMediaFullCover]:
              (heroImageFullCover && !isMobile) ||
              (isMobile &&
                (heroImageMobileFullCover ||
                  heroImageMobileFitContentOnCover) &&
                hasNonMediaContents(props)),
          })}
          style={{
            ...style,
            ...(heroMediaSize && !isMobile
              ? heroVideoEmbed
                ? {
                    minHeight: `${minHeight}px`,
                  }
                : heroMediaPadding
                ? { padding: heroMediaPadding }
                : {}
              : {}),
          }}
        >
          {heroVideo || heroVideoEmbed ? (
            <>
              {heroVideoEmbed ? (
                <div className={styles.HeroCardMediaVideoContainer}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: heroVideoEmbed as string,
                    }}
                  />
                </div>
              ) : (
                <div
                  className={cn(styles.HeroCardMediaVideoContainer, {
                    [styles.HeroCardMediaVideoContainerPlaying]: playing,
                  })}
                >
                  <div>
                    <video
                      ref={ref}
                      autoPlay={heroVideoAutoplay || false}
                      loop={heroVideoLoop || false}
                      muted={heroVideoMuted || false}
                      disablePictureInPicture
                      onClick={handleClick}
                      onEnded={(e: any) => {
                        const _this = e.target || ref.current;
                        if (_this) {
                          _this.currentTime = 0;
                          setVideoTime(0);
                        }
                        setPlaying(heroVideoLoop || false);
                      }}
                      preload="auto"
                      src={heroVideo}
                      style={
                        !playing && heroVideoThumbnail
                          ? { display: 'none' }
                          : {}
                      }
                    />
                    {!playing && heroVideoThumbnail && (
                      <img
                        src={heroVideoThumbnail}
                        loading="lazy"
                        onClick={handleClick}
                      />
                    )}
                    {!playing && (
                      <SvgIcon
                        type={IconEnum.play}
                        color="#323334"
                        fill="#323334"
                        size="100%"
                        onClick={handleClick}
                      />
                    )}
                  </div>
                  {renderDisclaimer()}
                </div>
              )}
            </>
          ) : (
            <>
              {hero360ViewUrl ? (
                <>
                  <div
                    className={styles.HeroCardMedia360Container}
                    style={
                      !isMobile && cardFullscreen && heroMediaSize
                        ? {
                            aspectRatio: '16/9',
                          }
                        : {}
                    }
                  >
                    {!viewing && hero360ViewThumbnail ? (
                      <>
                        <img
                          src={hero360ViewThumbnail}
                          loading="lazy"
                          onClick={() => setViewing(true)}
                        />
                        <SvgIcon
                          type={IconEnum.circularArrows}
                          color={`#${colorWhite}`}
                          fill={`#${colorWhite}`}
                          size="100%"
                          onClick={() => setViewing(true)}
                        />
                      </>
                    ) : (
                      <iframe allowFullScreen src={hero360ViewUrl} />
                    )}
                    {renderDisclaimer()}
                  </div>
                </>
              ) : (
                <div
                  ref={mediaRef}
                  style={{
                    ...(mediaRefHeight && mediaRefHeight > 0
                      ? { height: `${mediaRefHeight}px` }
                      : {}),
                  }}
                  className={styles.HeroCardMediaImageContainer}
                >
                  <img
                    src={isMobile ? heroMobileImage || heroImage : heroImage}
                    loading="lazy"
                  />
                  {renderDisclaimer()}
                </div>
              )}
              {visible && _featureIcons && _featureIcons.length > 0 && (
                <div className={cn(styles.HeroCardFeatureIconsContainer)}>
                  <animated.div
                    className={styles.HeroCardFeatureIcons}
                    style={animateStyle}
                  >
                    {_featureIcons.map((feature, idx) =>
                      feature.contentType === 'ImageIcon'
                        ? ImageIcon(idx, feature)
                        : feature.contentType === 'AnimatedNumberIcon'
                        ? AnimatedNumberIcon(idx, feature)
                        : feature.contentType === 'CtaIcon'
                        ? CallToActionIcon(
                            idx,
                            feature,
                            styles.HeroCardFeatureIconCTA
                          )
                        : null
                    )}
                  </animated.div>
                </div>
              )}
              {visible && featureInfo && featureInfo.length > 0 && (
                <div
                  ref={featureInfoRef}
                  className={cn(
                    styles.HeroCardFeatureInfosContainer,
                    styles[
                      `HeroCardFeatureInfosContainer${featureInfoPosition}`
                    ]
                  )}
                >
                  <div
                    className={cn(
                      styles.HeroCardFeatureInfos,
                      styles[`HeroCardFeatureInfos${featureInfoPosition}`]
                    )}
                  >
                    <animated.div style={animateStyle}>
                      {featureInfo.map((feature, idx) => (
                        <HeroCardFeatureInfo
                          key={idx}
                          {...{ ...feature, idx, featureInfoPosition }}
                        />
                      ))}
                    </animated.div>
                  </div>
                </div>
              )}
              {visible && _featurePins && _featurePins.length > 0 && (
                <HeroCardMediaPins
                  {...{
                    pins: _featurePins,
                    featureToggles,
                    visible,
                    attachTogglesOnPins,
                    id,
                  }}
                />
              )}

              {visible && !isMobile && !attachTogglesOnPins && (
                <HeroCardMediaToggles
                  {...{
                    featureToggles,
                    visible,
                  }}
                />
              )}
            </>
          )}
        </div>
      )}
      {visible && isMobile && featureToggles && featureToggles.length > 0 && (
        <div className={styles.HeroCardMediaAccordionContainer}>
          {featureToggles.map((toggle, idx) => (
            <HeroCardMediaAccordion
              key={idx}
              {...{ ...toggle, idx, visible, isExpand: idx === 0 }}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default HeroCardMedia;
