import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import styles from '../index.module.scss';
import { IHeroCardProps } from '../store';
import { animated, useSpring } from '@react-spring/web';
import { mediaAnimationProps } from '../common';
import { colorBlack, colorPrimary } from '~/common/utils';

const HeroCardContentHeader = React.memo(
  ({
    contentHeader,
    headerDisclaimer,
    visible,
    contentFontColor,
    heroImageFullCover,
    heroImageMobileFullCover,
    heroImageMobileFitContentOnCover,
    isMobile,
  }: IHeroCardProps) => {
    const animateStyle = useSpring(mediaAnimationProps(visible));

    const applyColor = () =>
      (heroImageFullCover && !isMobile) ||
      (isMobile &&
        (heroImageMobileFullCover || heroImageMobileFitContentOnCover));

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (ref.current) {
        const lastChild = ref.current.lastChild as HTMLElement;
        const parentElement = ref.current.parentElement as HTMLElement;
        parentElement.style.textAlign = lastChild?.style?.textAlign;
      }
    }, [ref.current]);

    return (
      <>
        {contentHeader && (
          <animated.div
            className={styles.HeroCardContentHeader}
            style={animateStyle}
          >
            <div
              className={cn(styles.HeroCardContentHeaderText, {
                [styles.ContentWithDisclaimer]: headerDisclaimer,
              })}
              dangerouslySetInnerHTML={{ __html: contentHeader as string }}
              style={{
                color: `#${contentFontColor}`,
              }}
              ref={ref}
            />
            {headerDisclaimer && (
              <span
                className={cn(`tt ${styles.HeaderDisclaimerTrigger}`)}
                style={
                  applyColor()
                    ? contentFontColor && contentFontColor !== colorBlack
                      ? {
                          color: `#${contentFontColor}`,
                        }
                      : {}
                    : {
                        color: `#${colorPrimary}`,
                      }
                }
              >
                *
                <span
                  className="tt__content"
                  dangerouslySetInnerHTML={{
                    __html: headerDisclaimer as string,
                  }}
                />
              </span>
            )}
          </animated.div>
        )}
      </>
    );
  }
);
export default HeroCardContentHeader;
