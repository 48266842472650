import React from 'react';
import cn from 'classnames';
import styles from '../index.module.scss';
import { firstLetterToUpper } from '~/common/utils';
import { useSpring, animated } from 'react-spring';
import { IHeroCardProps } from '../store';
import { mediaAnimationProps } from '../common';

const HeroCardArticles = React.memo(
  ({
    cardType,
    articles,
    visible,
    articleFontColor,
    articleBackgroundColor,
    swapArticleThumbnailPosition,
  }: IHeroCardProps) => {
    const animateStyle = useSpring(mediaAnimationProps(visible));
    const _articles = articles?.filter((x) => !x.hidden);
    return (
      <>
        {_articles && _articles.length > 0 && (
          <animated.div
            className={cn(
              styles.HeroCardArticles,
              styles[`HeroCardArticles${cardType}`]
            )}
            style={animateStyle}
          >
            {_articles?.map((article, idx) => {
              const _details: string[] = [];
              if (article.author) _details.push(article.author);
              if (article.readingTime) _details.push(article.readingTime);
              return (
                <a
                  key={idx}
                  className={cn(
                    styles.HeroCardArticleContent,
                    styles[
                      `HeroCardArticleContent${firstLetterToUpper(cardType)}`
                    ],
                    {
                      [styles.HeroCardArticleContentSwap]:
                        swapArticleThumbnailPosition,
                    }
                  )}
                  href={article.url}
                  target={article.linkTarget || '_self'}
                  style={{
                    ...(articleBackgroundColor
                      ? { backgroundColor: `#${articleBackgroundColor}` }
                      : {}),
                    ...(articleFontColor
                      ? { color: `#${articleFontColor}` }
                      : {}),
                  }}
                >
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: article.title as string,
                      }}
                    />
                    {_details.length > 0 && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: _details.join(
                            '&nbsp;&nbsp;|&nbsp;&nbsp;'
                          ) as string,
                        }}
                      />
                    )}
                  </div>
                  <div>
                    <img src={article.thumbnailImageUrl} loading="lazy" />
                  </div>
                </a>
              );
            })}
          </animated.div>
        )}
      </>
    );
  }
);

export default HeroCardArticles;
