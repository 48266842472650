import { useEffect, useState } from 'react';

export const useNavOffsetHeight = (
  isMobile = false,
  defaultHeightOffset = 0
) => {
  const [offsetHeight, setOffsetHeight] = useState(defaultHeightOffset);

  const calcOffsetHeight = (children?: Element[]) => {
    setOffsetHeight(
      children && children.length > 0
        ? children[0]?.clientHeight || defaultHeightOffset
        : defaultHeightOffset
    );
  };

  useEffect(() => {
    setTimeout(() => {
      const pageHeaderContainer = document.getElementById('clientHeader');
      if (pageHeaderContainer) {
        const children = [].slice.call(
          pageHeaderContainer?.getElementsByClassName(
            isMobile ? 'mobile-nav' : 'primary-nav'
          ) || []
        );
        calcOffsetHeight(children);
      } else {
        const megaNav = document.getElementById('mega-nav');
        const children = [].slice.call(
          megaNav?.getElementsByClassName('full-device-width') || []
        );
        calcOffsetHeight(children);
      }
    }, 0);
  }, [isMobile, defaultHeightOffset]);

  return offsetHeight;
};
