import React from 'react';
import styles from '../index.module.scss';
import { IHeroCardProps } from '../store';
import { animated, useSpring } from '@react-spring/web';
import { mediaAnimationProps } from '../common';

const HeroCardContentBody = React.memo(
  ({ contentBody, visible, contentFontColor }: IHeroCardProps) => {
    const animateStyle = useSpring(mediaAnimationProps(visible));
    return (
      <>
        {HeroCardContentBody && (
          <animated.div
            className={styles.HeroCardContentBody}
            dangerouslySetInnerHTML={{ __html: contentBody as string }}
            style={animateStyle}
            ref={(element) => {
              if (element && contentFontColor) {
                element.style.setProperty(
                  'color',
                  `#${contentFontColor}`,
                  'important'
                );
              }
            }}
          />
        )}
      </>
    );
  }
);
export default HeroCardContentBody;
