import React from 'react';
import styles from '../index.module.scss';
import { hexToRgbA } from '~/common/utils';
import { IHeroCardProps } from '../store';
import {
  HeroCardContent,
  HeroCardContentBody,
  HeroCardContentHeader,
  HeroCardCTAs,
  HeroCardMedia,
  HeroCardArticles,
} from '../components';

const ImageOnTop = React.memo((props: IHeroCardProps) => {
  const {
    heroImageFullCover,
    contentBackgroundColor,
    contentCTAs,
    contentHeader,
    articles,
    contentVerticalAlignment,
  } = props;

  const showHeader = () =>
    (contentCTAs && contentCTAs.filter((x) => !x.ctaHidden).length > 0) ||
    contentHeader;

  const showArticles = () => articles && articles.length > 0;

  const mapContentAlignment = () => {
    switch (contentVerticalAlignment?.toLowerCase()) {
      case 'top':
        return 'start';
      case 'space between':
        return 'space-between';
      case 'center':
        return 'center';
      case 'bottom':
      default:
        return 'end';
    }
  };

  return (
    <>
      <HeroCardMedia {...props} />
      <HeroCardContent
        {...props}
        className={{
          [styles.HeroCardContentFullCover]: heroImageFullCover,
        }}
        style={{
          ...(heroImageFullCover && contentBackgroundColor
            ? {
                background: `linear-gradient(
              to bottom,
              transparent 0%,
              ${hexToRgbA(`#${contentBackgroundColor}`, '1')} 100%
            )`,
              }
            : {}),
          justifyContent: mapContentAlignment(),
        }}
      >
        {showHeader() ? (
          <div className={styles.HeroCardContentHeaderContainer}>
            <HeroCardContentHeader {...props} />
            <HeroCardCTAs {...props} />
          </div>
        ) : (
          <></>
        )}
        <div
          className={styles.HeroCardContentBodyContainer}
          style={
            !showHeader() && !showArticles()
              ? {
                  paddingLeft: '0px',
                  paddingRight: '0px',
                }
              : {}
          }
        >
          <HeroCardContentBody {...props} />
        </div>
        <HeroCardArticles {...props} />
      </HeroCardContent>
    </>
  );
});
export default ImageOnTop;
